const actions = {
  GET_INVESTMENT_LIST: 'GET_INVESTMENT_LIST',
  GET_INVESTMENT_LIST_SUCCESS: 'GET_INVESTMENT_LIST_SUCCESS',
  GET_INVESTMENT_DETAIL: 'GET_INVESTMENT_DETAIL',
  CREATE_INVEST_PACKAGE: 'CREATE_INVEST_PACKAGE',
  UPDATE_INVEST_PACKAGE: 'UPDATE_INVEST_PACKAGE',
  DELETE_INVEST_PACKAGE: 'DELETE_INVEST_PACKAGE',
  GET_INVESTMENT_HISTORY_LIST: 'GET_INVESTMENT_HISTORY_LIST',
  GET_INVESTMENT_HISTORY_LIST_SUCCESS: 'GET_INVESTMENT_HISTORY_LIST_SUCCESS',
  GET_INVESTMENT_HISTORY_DETAIL: 'GET_INVESTMENT_HISTORY_DETAIL',
  SIGN_INVEST: 'SIGN_INVEST',
  MINT_NFT: 'MINT_NFT',
  UPDATE_IMAGE_NFT: 'UPDATE_IMAGE_NFT',
  SET_INVEST_DETAIL: 'SET_INVEST_DETAIL',
  UPDATE_DIVIDEND: 'UPDATE_DIVIDEND',
  GET_LIST_DIRECT_COMMISSION_INVEST: 'GET_LIST_DIRECT_COMMISSION_INVEST',
  GET_LIST_DIRECT_COMMISSION_INVEST_SUCCESS: 'GET_LIST_DIRECT_COMMISSION_INVEST_SUCCESS',
  GET_LIST_DIRECT_COMMISSION_INVEST_CREATE: 'GET_LIST_DIRECT_COMMISSION_INVEST_CREATE',
  GET_LIST_DIRECT_COMMISSION_INVEST_UPDATE: 'GET_LIST_DIRECT_COMMISSION_INVEST_UPDATE',
  GET_LIST_DIRECT_COMMISSION_INVEST_DELETE: 'GET_LIST_DIRECT_COMMISSION_INVEST_DELETE',

  getListInvestment: (payload, cbe) => ({
    type: actions.GET_INVESTMENT_LIST,
    payload,
    cbe,
  }),

  getListInvestmentSuccess: (payload, cbe) => ({
    type: actions.GET_INVESTMENT_LIST_SUCCESS,
    payload,
    cbe,
  }),

  getDetailInvestment: (payload, cbe) => ({
    type: actions.GET_INVESTMENT_DETAIL,
    payload: payload,
    cbe,
  }),

  createInvestPackage: (payload, cbe, cbs) => ({
    type: actions.CREATE_INVEST_PACKAGE,
    payload,
    cbe,
    cbs,
  }),

  updateInvestPackage: (payload, cbe) => ({
    type: actions.UPDATE_INVEST_PACKAGE,
    payload,
    cbe,
  }),

  deleteInvestPackage: (payload, cbe) => ({
    type: actions.DELETE_INVEST_PACKAGE,
    payload,
    cbe,
  }),

  getListInvestmentHistory: (payload, cbe) => ({
    type: actions.GET_INVESTMENT_HISTORY_LIST,
    payload,
    cbe,
  }),
  getListInvestmentHistorySuccess: (payload, cbe) => ({
    type: actions.GET_INVESTMENT_HISTORY_LIST_SUCCESS,
    payload,
    cbe,
  }),

  getDetailInvestmentHistory: (payload, cbe) => ({
    type: actions.GET_INVESTMENT_HISTORY_DETAIL,
    payload: payload,
    cbe,
  }),

  signInvest: (payload, cbe) => ({
    type: actions.SIGN_INVEST,
    payload,
    cbe,
  }),

  mintNft: (payload, cbe) => ({
    type: actions.MINT_NFT,
    payload,
    cbe,
  }),

  updateImageNft: (payload, cbe) => ({
    type: actions.UPDATE_IMAGE_NFT,
    payload,
    cbe,
  }),

  setInvestDetail: (payload) => ({
    type: actions.SET_INVEST_DETAIL,
    payload: payload,
  }),

  updateDividend: (payload, cbe) => ({
    type: actions.UPDATE_DIVIDEND,
    payload: payload,
    cbe,
  }),

  getListDirectCommissionInvest: (payload, cbe, cbs) => ({
    type: actions.GET_LIST_DIRECT_COMMISSION_INVEST,
    payload: payload,
    cbe,
    cbs,
  }),
  createDirectCommissionInvest: (payload, cbe, cbs) => ({
    type: actions.GET_LIST_DIRECT_COMMISSION_INVEST_CREATE,
    payload: payload,
    cbe,
    cbs,
  }),
  updateDirectCommissionInvest: (payload, cbe, cbs) => ({
    type: actions.GET_LIST_DIRECT_COMMISSION_INVEST_UPDATE,
    payload: payload,
    cbe,
    cbs,
  }),
  deleteDirectCommissionInvest: (payload, cbe, cbs) => ({
    type: actions.GET_LIST_DIRECT_COMMISSION_INVEST_DELETE,
    payload: payload,
    cbe,
    cbs,
  }),
}
export default actions
