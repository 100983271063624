const actions = {
  UPLOAD_FILE: 'UPLOAD_FILE',
  UPLOAD_FILE_SUCCESS: 'UPLOAD_FILE_SUCCESS',

  uploadFile: (payload, cbs, cbe) => ({
    type: actions.UPLOAD_FILE,
    payload,
    cbs,
    cbe,
  }),
}
export default actions

export const fileData = null
