import { all, call, put, takeLatest } from 'redux-saga/effects'
import * as qs from 'query-string'
import fetchHelper from 'library/FetchHelper'
import { ROOT_API } from 'config/constants'
import actions from './actions'

/*  */
function submitDataRateFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/dividendInvest/create`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* submitDataRate({ payload, cbe, cbs }) {
  try {
    const { data } = yield call(submitDataRateFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else if (cbe) cbe(data)
  } catch (error) {
    console.error('submitDataRate', error)
    if (cbe) cbe(error)
  }
}

/*  */
function getDividendInvestFromApi(payload) {
  const qStr = qs.stringify(payload)
  return fetchHelper
    .fetch(`${ROOT_API}/admin/dividendInvest/list?${qStr}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getDividendInvest({ payload }) {
  try {
    const { data } = yield call(getDividendInvestFromApi, payload)
    if (data.code === 200) {
      yield put(actions.getDividendInvestSuccess(data.data))
    }
  } catch (error) {
    console.error('getDividendInvest', error)
  }
}
/*  */
function updateDividendInvestFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/dividendInvest/update`, {
      method: 'PUT',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* updateDividendInvest({ payload, cbe, cbs }) {
  try {
    const { data } = yield call(updateDividendInvestFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else if (cbe) cbe(data)
  } catch (error) {
    console.error('updateDividendInvest', error)
    if (cbe) cbe(error)
  }
}
/*  */
function deleteDividendInvestFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/dividendInvest/delete`, {
      method: 'DELETE',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* deleteDividendInvest({ payload, cbe, cbs }) {
  try {
    const { data } = yield call(deleteDividendInvestFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else if (cbe) cbe(data)
  } catch (error) {
    console.error('deleteDividendInvest', error)
    if (cbe) cbe(error)
  }
}

export default function* dividendInvestSaga() {
  yield all([
    takeLatest(actions.SUBMIT_DATA_RATE, submitDataRate),
    takeLatest(actions.GET_DIVIDEND_INVEST, getDividendInvest),
    takeLatest(actions.UPDATE_DIVIDEND_INVEST, updateDividendInvest),
    takeLatest(actions.DELETE_DIVIDEND_INVEST, deleteDividendInvest),
  ])
}
