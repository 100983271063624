import actions from './actions'

const initState = { listBlockChain: null }

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_LIST_BLOCK_CHAIN_SUCCESS:
      const mapKeyNetwork = {
        eth: 'eth',
        BEP20: 'bsc',
        fantom: 'fantom',
        POLYGON: 'polygon',
        avalanche: 'avalanche',
      }
      const customData = action.data.data.map((it) => ({
        ...it,
        chain_code: mapKeyNetwork[it.code],
      }))

      return {
        ...state,
        listBlockChain: {
          data: customData,
        },
      }
    default:
      return state
  }
}
