import actions from './actions'

const initState = { dividendInvest: undefined }

export default function dividendInvestReducer(state = initState, action) {
  const { data } = action
  switch (action.type) {
    case actions.GET_DIVIDEND_INVEST_SUCCESS:
      return {
        ...state,
        dividendInvest: data,
      }

    default:
      return state
  }
}
