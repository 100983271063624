import { all } from 'redux-saga/effects'
import authSagas from '@iso/redux/auth/saga'
import profileSaga from '@iso/redux/profile/saga'
import userSaSga from '@iso/redux/user/saga'
import customerSaSga from '@iso/redux/customer/saga'
import transactionSaSga from '@iso/redux/transaction/saga'
import walletSaSga from '@iso/redux/wallet/saga'
import walletChainSaSga from '@iso/redux/adminWallet/saga'
import blockChainSaSga from '@iso/redux/blockChain/saga'
import currencySaSga from '@iso/redux/currency/saga'
import stakingSaga from '@iso/redux/staking/saga'
import gameSaga from '@iso/redux/game/saga'
import fileSaga from '@iso/redux/file/saga'
import investmentAirdropSaga from '@iso/redux/investmentAirdrop/saga'
import dividendInvestSaga from '@iso/redux/dividendInvest/saga'
import dividendCommissionInvestSaga from '@iso/redux/dividendCommissionInvest/saga'
import investmentSaga from '@iso/redux/investment/saga'
import claimTokenSaga from '@iso/redux/claimToken/saga'
import walletTeamSaga from '@iso/redux/teamWallet/saga'
import systemCommissionSaga from '@iso/redux/systemCommission/saga'

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    profileSaga(),
    userSaSga(),
    customerSaSga(),
    transactionSaSga(),
    walletSaSga(),
    walletChainSaSga(),
    blockChainSaSga(),
    currencySaSga(),
    stakingSaga(),
    gameSaga(),
    fileSaga(),
    investmentAirdropSaga(),
    dividendInvestSaga(),
    dividendCommissionInvestSaga(),
    investmentSaga(),
    claimTokenSaga(),
    walletTeamSaga(),
    systemCommissionSaga(),
  ])
}
