import { all, call, put, takeLatest, takeEvery } from 'redux-saga/effects'
import fetchHelper from 'library/FetchHelper'
import { ROOT_API } from 'config/constants'
import actions from './actions'
import * as qs from 'query-string'

function getListInvestmentFromApi(payload) {
  const qStr = qs.stringify(payload)

  return fetchHelper
    .fetch(`${ROOT_API}/admin/investPackage/list?${qStr}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getListInvestment({ payload, cbe, cbs }) {
  try {
    const { data } = yield call(getListInvestmentFromApi, payload)
    if (data.code === 200) {
      yield put({
        type: actions.GET_INVESTMENT_LIST_SUCCESS,
        payload: data.data,
      })
      if (cbe) cbe(data)
    } else {
      yield put({
        type: actions.GET_INVESTMENT_LIST_SUCCESS,
        payload: null,
      })
    }
  } catch (error) {
    console.error('getListInvestment', error)
    if (cbe) cbe(error)
  }
}

function getDetailInvestmentFromApi(payload) {
  const qStr = qs.stringify(payload)

  return fetchHelper
    .fetch(`${ROOT_API}/admin/investPackage/detail?${qStr}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getDetailInvestment({ payload, cbe }) {
  try {
    const { data } = yield call(getDetailInvestmentFromApi, payload)
    if (data.code === 200) {
      if (cbe) cbe(data)
    }
  } catch (error) {
    console.error('getDetailInvestment', error)
    if (cbe) cbe(error)
  }
}

function createInvestPackageFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/investPackage/create`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* createInvestPackage({ payload, cbe, cbs }) {
  try {
    const { data } = yield call(createInvestPackageFromApi, payload)
    if (data.code === 200) {
      if (cbe) cbe(data)
    }
  } catch (error) {
    console.error('createInvestPackage', error)
    if (cbs) cbs(error)
  }
}

function updateInvestPackageFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/investPackage/update`, {
      method: 'PUT',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* updateInvestPackage({ payload, cbe }) {
  try {
    const { data } = yield call(updateInvestPackageFromApi, payload)
    if (data.code === 200) {
      if (cbe) cbe(data)
    }
  } catch (error) {
    console.error('updateInvestPackage', error)
    if (cbe) cbe(error)
  }
}

function deleteInvestPackageFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/investPackage/delete`, {
      method: 'DELETE',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* deleteInvestPackage({ payload, cbe }) {
  try {
    const { data } = yield call(deleteInvestPackageFromApi, payload)
    if (data.code === 200) {
      if (cbe) cbe(data)
    }
  } catch (error) {
    console.error('updateInvestPackage', error)
    if (cbe) cbe(error)
  }
}

function getListInvestmentHistoryFromApi(payload) {
  const qStr = qs.stringify(payload)

  return fetchHelper
    .fetch(`${ROOT_API}/admin/investment/list?${qStr}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getListInvestmentHistory({ payload, cbe }) {
  try {
    const { data } = yield call(getListInvestmentHistoryFromApi, payload)
    if (data.code === 200) {
      yield put({
        type: actions.GET_INVESTMENT_HISTORY_LIST_SUCCESS,
        payload: data.data,
      })
      if (cbe) cbe(data.data)
    } else {
      yield put({
        type: actions.GET_INVESTMENT_HISTORY_LIST_SUCCESS,
        payload: null,
      })
    }
  } catch (error) {
    console.error('getListInvestmentHistory', error)
    if (cbe) cbe(error)
  }
}

function getDetailInvestmentHistoryFromApi(payload) {
  const qStr = qs.stringify(payload)

  return fetchHelper
    .fetch(`${ROOT_API}/admin/investment/detail?${qStr}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getDetailInvestmentHistory({ payload, cbe, cbs }) {
  try {
    const { data } = yield call(getDetailInvestmentHistoryFromApi, payload)
    if (data.code === 200) {
      if (cbe) cbe(data)
    }
  } catch (error) {
    console.error('getDetailInvestmentHistory', error)
    if (cbe) cbe(error)
  }
}

function signInvestFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/investment/signInvest`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* signInvest({ payload, cbe }) {
  try {
    const { data } = yield call(signInvestFromApi, payload)
    if (data.code === 200) {
      if (cbe) cbe(data)
    }
  } catch (error) {
    console.error('signInvest', error)
    if (cbe) cbe(error)
  }
}

function mintNftFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/investment/mintNft`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* mintNft({ payload, cbe }) {
  try {
    const { data } = yield call(mintNftFromApi, payload)
    if (data.code === 200) {
      if (cbe) cbe(data)
    }
  } catch (error) {
    console.error('mintNft', error)
    if (cbe) cbe(error)
  }
}

function updateImageNftFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/investment/updateImageNft`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* updateImageNft({ payload, cbe }) {
  try {
    const { data } = yield call(updateImageNftFromApi, payload)
    if (data.code === 200) {
      if (cbe) cbe(data)
    }
  } catch (error) {
    console.error('updateImageNft', error)
    if (cbe) cbe(error)
  }
}

function updateDividendFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/investment/dividend`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* updateDividend({ payload, cbe }) {
  try {
    const { data } = yield call(updateDividendFromApi, payload)
    if (data.code === 200) {
      if (cbe) cbe(data)
    }
  } catch (error) {
    console.error('updateDividend', error)
    if (cbe) cbe(error)
  }
}

// Commission
function getListDirectCommissionInvestFromApi(payload) {
  const qStr = qs.stringify(payload)
  return fetchHelper
    .fetch(`${ROOT_API}/admin/directCommission/list?${qStr}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getListDirectCommissionInvest({ payload, cbe }) {
  try {
    const { data } = yield call(getListDirectCommissionInvestFromApi, payload)
    if (data.code === 200) {
      yield put({
        type: actions.GET_LIST_DIRECT_COMMISSION_INVEST_SUCCESS,
        payload: data.data,
        params: payload,
      })
      if (cbe) cbe(data)
    } else {
      yield put({
        type: actions.GET_LIST_DIRECT_COMMISSION_INVEST_SUCCESS,
        payload: null,
        params: payload,
      })
    }
  } catch (error) {
    console.error('getListReward', error)
  }
}

function createDirectCommissionInvestFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/directCommission/create`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* createDirectCommissionInvest({ payload, cbe, cbs }) {
  try {
    const { data } = yield call(createDirectCommissionInvestFromApi, payload)
    if (data.code === 200) {
      if (cbe) cbe(data.data)
    } else if (cbs) cbs(data)
  } catch (error) {
    if (cbs) cbs(error)
    console.error('createDirectCommissionInvest', error)
  }
}

function updateDirectCommissionInvestFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/directCommission/update`, {
      method: 'PUT',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* updateDirectCommissionInvest({ payload, cbe, cbs }) {
  try {
    const { data } = yield call(updateDirectCommissionInvestFromApi, payload)
    if (data.code === 200) {
      if (cbe) cbe(data.data)
    } else if (cbs) cbs(data)
  } catch (error) {
    if (cbs) cbs(error)
    console.error('updateDirectCommissionInvest', error)
  }
}

function deleteDirectCommissionInvestFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/directCommission/delete`, {
      method: 'DELETE',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* deleteDirectCommissionInvest({ payload, cbe, cbs }) {
  try {
    const { data } = yield call(deleteDirectCommissionInvestFromApi, payload)
    if (data.code === 200) {
      if (cbe) cbe(data.data)
    } else if (cbs) cbs(data)
  } catch (error) {
    if (cbs) cbs(error)
    console.error('deleteDirectCommissionInvest', error)
  }
}

export default function* investmentSaga() {
  yield all([
    takeLatest(actions.GET_INVESTMENT_LIST, getListInvestment),
    takeLatest(actions.GET_INVESTMENT_DETAIL, getDetailInvestment),
    takeLatest(actions.CREATE_INVEST_PACKAGE, createInvestPackage),
    takeLatest(actions.UPDATE_INVEST_PACKAGE, updateInvestPackage),
    takeLatest(actions.DELETE_INVEST_PACKAGE, deleteInvestPackage),
    takeEvery(actions.GET_INVESTMENT_HISTORY_LIST, getListInvestmentHistory),
    takeLatest(actions.GET_INVESTMENT_HISTORY_DETAIL, getDetailInvestmentHistory),
    takeLatest(actions.SIGN_INVEST, signInvest),
    takeLatest(actions.MINT_NFT, mintNft),
    takeLatest(actions.UPDATE_IMAGE_NFT, updateImageNft),
    takeLatest(actions.UPDATE_DIVIDEND, updateDividend),
    takeLatest(actions.GET_LIST_DIRECT_COMMISSION_INVEST, getListDirectCommissionInvest),
    takeLatest(actions.GET_LIST_DIRECT_COMMISSION_INVEST_CREATE, createDirectCommissionInvest),
    takeLatest(actions.GET_LIST_DIRECT_COMMISSION_INVEST_UPDATE, updateDirectCommissionInvest),
    takeLatest(actions.GET_LIST_DIRECT_COMMISSION_INVEST_DELETE, deleteDirectCommissionInvest),
  ])
}
