const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  GET_LIST_CURRENCY: 'GET_LIST_CURRENCY',
  GET_LIST_CURRENCY_SUCCESS: 'GET_LIST_CURRENCY_SUCCESS',

  GET_ADDRESS_BY_CURRENCY_LIST: 'GET_ADDRESS_BY_CURRENCY_LIST',
  GET_ADDRESS_BY_CURRENCY_LIST_SUCCESS: 'GET_ADDRESS_BY_CURRENCY_LIST_SUCCESS',

  GET_DETAIL_CURRENCY: 'GET_DETAIL_CURRENCY',
  CREATE_CURRENCY: 'CREATE_CURRENCY',
  UPDATE_CURRENCY: 'UPDATE_CURRENCY',
  DELETE_CURRENCY_ATTR: 'DELETE_CURRENCY_ATTR',

  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),

  getListCurrency: (payload) => ({
    type: actions.GET_LIST_CURRENCY,
    payload,
  }),
  getListCurrencySuccess: (payload, data) => ({
    type: actions.GET_LIST_CURRENCY_SUCCESS,
    payload,
    data,
  }),

  getAddressByCurrencyList: (payload) => ({
    type: actions.GET_ADDRESS_BY_CURRENCY_LIST,
    payload,
  }),
  getAddressByCurrencyListSuccess: (payload, data) => ({
    type: actions.GET_ADDRESS_BY_CURRENCY_LIST_SUCCESS,
    payload,
    data,
  }),

  getDetailCurrency: (payload, cbs, cbe) => ({
    type: actions.GET_DETAIL_CURRENCY,
    payload,
    cbs,
    cbe,
  }),

  createCurrency: (payload, cbs, cbe) => ({
    type: actions.CREATE_CURRENCY,
    payload,
    cbs,
    cbe,
  }),

  updateCurrency: (payload, cbs, cbe) => ({
    type: actions.UPDATE_CURRENCY,
    payload,
    cbs,
    cbe,
  }),

  deleteCurrencyAttr: (payload, cbs, cbe) => ({
    type: actions.DELETE_CURRENCY_ATTR,
    payload,
    cbs,
    cbe,
  }),
}
export default actions
