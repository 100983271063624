const actions = {
  GET_CLAIM_HISTORY_LIST: 'GET_CLAIM_HISTORY_LIST',
  GET_CLAIM_HISTORY_LIST_SUCCESS: 'GET_CLAIM_HISTORY_LIST_SUCCESS',
  CLAIM_TOKEN_DEPOSIT: 'CLAIM_TOKEN_DEPOSIT',

  getListClaimHistory: (payload, cbe) => ({
    type: actions.GET_CLAIM_HISTORY_LIST,
    payload,
    cbe,
  }),

  getListClaimHistorySuccess: (payload, cbe) => ({
    type: actions.GET_CLAIM_HISTORY_LIST_SUCCESS,
    payload,
    cbe,
  }),

  claimTokenDeposit: (payload, cbe) => ({
    type: actions.CLAIM_TOKEN_DEPOSIT,
    payload,
    cbe,
  }),
}
export default actions
