import actions from './actions'

const initState = {
  listTeamWallet: null,
}

export default function WalletTeam(state = initState, action) {
  const { type, payload } = action

  switch (type) {
    case actions.GET_LIST_TEAM_WALLET_SUCCESS:
      return {
        ...state,
        listTeamWallet: payload,
      }
    default:
      return state
  }
}
