import { all, call, put, takeLatest } from 'redux-saga/effects'
import * as qs from 'query-string'
import fetchHelper from 'library/FetchHelper'
import { ROOT_API } from 'config/constants'
import actions from './actions'

/*  */
function createDividendCommissionFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/dividendCommission/create`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* createDividendCommission({ payload, cbe, cbs }) {
  try {
    const { data } = yield call(createDividendCommissionFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else if (cbe) cbe(data)
  } catch (error) {
    console.error('submitDataRate', error)
    if (cbe) cbe(error)
  }
}
/*  */
function getDividendCommissionInvestFromApi(payload) {
  const qStr = qs.stringify(payload)
  return fetchHelper
    .fetch(`${ROOT_API}/admin/dividendCommission/list?${qStr}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getDividendCommissionInvest({ payload }) {
  try {
    const { data } = yield call(getDividendCommissionInvestFromApi, payload)
    if (data.code === 200) {
      yield put(actions.getDividendCommissionInvestSuccess(data.data))
    }
  } catch (error) {
    console.error('getDividendCommissionInvest', error)
  }
}

/*  */
function updateDividendCommissionInvestFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/dividendCommission/update`, {
      method: 'PUT',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* updateDividendCommissionInvest({ payload, cbe, cbs }) {
  try {
    const { data } = yield call(updateDividendCommissionInvestFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else if (cbe) cbe(data)
  } catch (error) {
    console.error('updateDividendCommissionInvest', error)
    if (cbe) cbe(error)
  }
}
/*  */
function deleteDividendInvestFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/dividendCommission/delete`, {
      method: 'DELETE',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* deleteDividendCommissionInvest({ payload, cbe, cbs }) {
  try {
    const { data } = yield call(deleteDividendInvestFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else if (cbe) cbe(data)
  } catch (error) {
    console.error('deleteDividendInvest', error)
    if (cbe) cbe(error)
  }
}

export default function* dividendInvestSaga() {
  yield all([
    takeLatest(actions.CREATE_DIVIDEND_COMMISSION, createDividendCommission),
    takeLatest(actions.GET_DIVIDEND_COMMISSION_INVEST, getDividendCommissionInvest),
    takeLatest(actions.UPDATE_DIVIDEND_COMMISSION_INVEST, updateDividendCommissionInvest),
    takeLatest(actions.DELETE_DIVIDEND_COMMISSION_INVEST, deleteDividendCommissionInvest),
  ])
}
