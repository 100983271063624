import { all, takeEvery, call, put, fork } from 'redux-saga/effects'
import fetchHelper from 'library/FetchHelper'
import { ROOT_API } from 'config/constants'
import actions from './actions'
import * as qs from 'query-string'
import { fetchDataAndCheckLocaleData } from 'services/Storage/adminWallet'

function getWalletChainFromApi(payload) {
  const qsString = qs.stringify(payload)
  return fetchHelper
    .fetch(`${ROOT_API}/admin/chainWallet/list?${qsString}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getAdminWalletList() {
  yield takeEvery(actions.GET_ADMIN_WALLET_LIST, function* ({ payload, cbs }) {
    try {
      const { data } = yield call(getWalletChainFromApi, payload)
      if (data.code === 200) {
        yield put(actions.getAdminWalletListSuccess(payload, data.data))
        if (cbs) cbs(data.data)
      }
    } catch (error) {
      console.error('getAdminWalletList', error)
    }
  })
}

function getDetailAdminWalletFromApi(payload) {
  const qStr = qs.stringify(payload)

  return fetchHelper
    .fetch(`${ROOT_API}/admin/chainWallet/detail?${qStr}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getDetailAdminWallet() {
  yield takeEvery(actions.GET_ADMIN_WALLET_DETAIL, function* ({ payload, cbs }) {
    try {
      const { data } = yield call(getDetailAdminWalletFromApi, payload)
      if (data.code === 200) {
        if (cbs) cbs(data.data)
      }
    } catch (error) {
      console.error('getDetailAdminWallet', error)
      if (cbs) cbs(error)
    }
  })
}

function createWalletChainFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/chainWallet/create`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* createWalletChain() {
  yield takeEvery(actions.CREATE_WALLET_CHAIN, function* ({ payload, cbs, cbe }) {
    try {
      const { data } = yield call(createWalletChainFromApi, payload, cbs, cbe)
      if (data.code === 200) {
        if (cbs) cbs(data.data)
      } else if (cbe) cbe(data)
    } catch (error) {
      console.error('createUser', error)
      if (cbe) cbe(error)
    }
  })
}

function updateWalletChainFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/chainWallet/update`, {
      method: 'PUT',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* updateWalletChain() {
  yield takeEvery(actions.UPDATE_WALLET_CHAIN, function* ({ payload, cbs, cbe }) {
    try {
      const { data } = yield call(updateWalletChainFromApi, payload, cbs, cbe)
      if (data.code === 200) {
        if (cbs) cbs(data.data)
      } else if (cbe) cbe(data)
    } catch (error) {
      console.error('updateWalletChain', error)
      if (cbe) cbe(error)
    }
  })
}

function deleteWalletChainFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/chainWallet/delete`, {
      method: 'DELETE',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* deleteWalletChain() {
  yield takeEvery(actions.DELETE_WALLET_CHAIN, function* ({ payload, cbs, cbe }) {
    try {
      const { data } = yield call(deleteWalletChainFromApi, payload, cbs, cbe)
      if (data.code === 200) {
        if (cbs) cbs(data.data)
      } else if (cbe) cbe(data)
    } catch (error) {
      console.error('deleteWalletChain', error)
      if (cbe) cbe(error)
    }
  })
}

/** */
async function fetchAdminWalletBalanceFromApi({ account, chain_code, tokens, isLoadBalance }) {
  // fetch and check data in locale storage is expired for load new balance
  return await fetchDataAndCheckLocaleData(account, chain_code, tokens, isLoadBalance)
}
function* fetchAdminWalletBalance() {
  yield takeEvery(actions.GET_ADMIN_WALLET_BALANCE, function* ({ payload }) {
    try {
      const response = yield call(fetchAdminWalletBalanceFromApi, payload)
      if (response.code === 200) {
        yield put(actions.fetchAdminWalletBalanceSuccess(payload, response.data))
      }
    } catch (error) {
      //
    }
  })
}

export default function* rootSaga() {
  yield all([
    // fork(getAdminWalletList),
    fork(getAdminWalletList),
    fork(getDetailAdminWallet),
    fork(createWalletChain),
    fork(updateWalletChain),
    fork(deleteWalletChain),
    fork(fetchAdminWalletBalance),
  ])
}
