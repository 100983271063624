import actions from './actions'

const initState = { listSystemCommission: null }

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_LIST_SYSTEM_COMMISSION_SUCCESS:
      return {
        ...state,
        listSystemCommission: action.data,
      }

    default:
      return state
  }
}
