const actions = {
  CREATE_INVESTMENT_AIRDROP: 'CREATE_INVESTMENT_AIRDROP',

  createInvestmentAirdrop: (payload, cbs, cbe) => ({
    type: actions.CREATE_INVESTMENT_AIRDROP,
    payload,
    cbs,
    cbe,
  }),
}
export default actions
