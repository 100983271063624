import { all, takeEvery, call, put, fork } from 'redux-saga/effects'
import fetchHelper from 'library/FetchHelper'
import { ROOT_API } from 'config/constants'
import actions from './actions'
import * as qs from 'query-string'

function getListSystemCommissionFromApi(payload) {
  const qsString = qs.stringify(payload)
  return fetchHelper
    .fetch(`${ROOT_API}/admin/systemCommission/list?${qsString}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getListSystemCommission() {
  yield takeEvery(actions.GET_LIST_SYSTEM_COMMISSION, function* ({ payload, cbs, cbe }) {
    try {
      const { data } = yield call(getListSystemCommissionFromApi, payload, cbs, cbe)
      if (data.code === 200) {
        if (cbs) cbs(data.data)
        yield put({
          type: actions.GET_LIST_SYSTEM_COMMISSION_SUCCESS,
          data: data.data,
        })
      } else if (cbe) cbe(data)
    } catch (error) {
      console.error('getListSystemCommission', error)
      if (cbe) cbe(error)
    }
  })
}

function createSystemCommissionFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/systemCommission/create`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* createSystemCommission() {
  yield takeEvery(actions.CREATE_SYSTEM_COMMISSION, function* ({ payload, cbs, cbe }) {
    try {
      const { data } = yield call(createSystemCommissionFromApi, payload)
      if (data.code === 200) {
        if (cbs) cbs(data)
      }
    } catch (error) {
      console.error('createSystemCommission', error)
      if (cbe) cbe(error)
    }
  })
}

function updateSystemCommissionFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/systemCommission/update`, {
      method: 'PUT',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* updateSystemCommission() {
  yield takeEvery(actions.UPDATE_SYSTEM_COMMISSION, function* ({ payload, cbs, cbe }) {
    try {
      const { data } = yield call(updateSystemCommissionFromApi, payload)
      if (data.code === 200) {
        if (cbs) cbs(data)
      }
    } catch (error) {
      console.error('updateSystemCommission', error)
      if (cbe) cbe(error)
    }
  })
}

function deleteSystemCommissionFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/systemCommission/delete`, {
      method: 'DELETE',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* deleteSystemCommission() {
  yield takeEvery(actions.DELETE_SYSTEM_COMMISSION, function* ({ payload, cbs, cbe }) {
    try {
      const { data } = yield call(deleteSystemCommissionFromApi, payload)
      if (data.code === 200) {
        if (cbs) cbs(data.data)
      } else if (cbe) cbe(data)
    } catch (error) {
      if (cbe) cbe(error)
      console.error('deleteSystemCommission', error)
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(getListSystemCommission),
    fork(createSystemCommission),
    fork(updateSystemCommission),
    fork(deleteSystemCommission),
  ])
}
