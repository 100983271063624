import { MORALIS_APP_API, MORALIS_APP_API_KEY } from 'config/constants'
import { createStorageTTL, getFromStorageTTL, MEDIUM_TTL } from '.'

/**
 * Fetch native balance and list currency by chain
 * @param {*} account => string
 * @param {*} currencyAddressListByKeyChain =>
 * {
 *    bsc: ['0x00...','0x011...',...],
 *    polygon: ['0x00...','0x011...',...],
 *    ...
 * }
 * @param {*} isLoadBalance => boolean | undefined
 * @returns
 */
export const fetchDataAndCheckLocaleData = async (account, chain_code, tokens, isLoadBalance) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        const cache_key = `z:a:w:${account}` // a for admin, w for wallet
        let data

        const content = getFromStorageTTL(cache_key)
        if (content !== null && !isLoadBalance) {
          data = content
        }

        // Ensure we have valid cached data
        if (data === undefined) {
          let result = {}
          try {
            const promiseList = []
            const promiseListBNB = []

            let qtr = ''
            tokens.forEach((addr) => {
              qtr += `&token_addresses=${addr}`
            })

            if (tokens && tokens?.length > 0) {
              promiseList.push(
                fetch(`${MORALIS_APP_API}/${account}/erc20?chain=${chain_code}${qtr}`, {
                  method: 'GET',
                  headers: {
                    'x-api-key': MORALIS_APP_API_KEY,
                  },
                }).then(async (res) => await res.json()),
              )
            }
            if (chain_code) {
              promiseListBNB.push(
                fetch(`${MORALIS_APP_API}/${account}/balance?chain=${chain_code}`, {
                  method: 'GET',
                  headers: {
                    'x-api-key': MORALIS_APP_API_KEY,
                  },
                }).then(async (res) => await res.json()),
              )
            }

            const responseTokens = await Promise.all(promiseList)
            const responseBNB = await Promise.all(promiseListBNB)

            result[chain_code] = {
              name: chain_code,
              symbol: chain_code,
              decimals: 18,
              balance: responseBNB?.[0]?.balance,
            }

            for (let j = 0; j < tokens.length; j++) {
              const item = responseTokens?.[0]?.[j]
              if (item) {
                result[item.symbol] = item
              }
            }
          } catch (err) {
            reject(err)
          }

          if (Object.keys(result).length > 0) {
            data = { code: 200, data: { [account]: result } }
            createStorageTTL(cache_key, data, MEDIUM_TTL)
          } else {
            data = { code: 429, data: undefined, message: 'Call limit time' }
          }
        }
        resolve(data)
      } catch (err) {
        reject(err)
      }
    })()
  })
}
