import { all, call, put, takeLatest, takeEvery } from 'redux-saga/effects'
import fetchHelper from 'library/FetchHelper'
import { ROOT_API } from 'config/constants'
import actions from './actions'
import * as qs from 'query-string'

function getListClaimHistoryFromApi(payload) {
  const qStr = qs.stringify(payload)

  return fetchHelper
    .fetch(`${ROOT_API}/admin/ClaimDeposit/list?${qStr}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getListClaimHistory({ payload, cbe }) {
  try {
    const { data } = yield call(getListClaimHistoryFromApi, payload)
    if (data.code === 200) {
      yield put({
        type: actions.GET_CLAIM_HISTORY_LIST_SUCCESS,
        payload: data.data,
      })
      if (cbe) cbe(data.data)
    } else {
      yield put({
        type: actions.GET_CLAIM_HISTORY_LIST_SUCCESS,
        payload: null,
      })
    }
  } catch (error) {
    console.error('getListClaimHistory', error)
    if (cbe) cbe(error)
  }
}

function claimTokenDepositFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/ClaimDeposit/crawlDeposit`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* claimTokenDeposit({ payload, cbe }) {
  try {
    const { data } = yield call(claimTokenDepositFromApi, payload)
    if (data.code === 200) {
      if (cbe) cbe(data)
    }
  } catch (error) {
    console.error('claimTokenDeposit', error)
    if (cbe) cbe(error)
  }
}

export default function* investmentSaga() {
  yield all([
    takeEvery(actions.GET_CLAIM_HISTORY_LIST, getListClaimHistory),
    takeLatest(actions.CLAIM_TOKEN_DEPOSIT, claimTokenDeposit),
  ])
}
