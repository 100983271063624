import React from 'react'
import { Provider } from 'react-redux'
import GlobalStyles from '@iso/assets/styles/globalStyle'
import { store } from './redux/store'
import Boot from './redux/boot'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Routes from './router'
import AppProvider from './AppProvider'
import ModalConfirm from 'components/Modals/ModalConfirm'

const App = () => (
  <>
    <ToastContainer />
    <Provider store={store}>
      <AppProvider>
        <>
          <GlobalStyles />
          <Routes />
          <ModalConfirm />
        </>
      </AppProvider>
    </Provider>
  </>
)
Boot()
  .then(() => App())
  .catch((error) => console.error(error))

export default App
