import actions from './actions'

const initState = {
  listClaimHistory: null,
}

export default function claimToken(state = initState, action) {
  const { type, payload } = action

  switch (type) {
    case actions.GET_CLAIM_HISTORY_LIST_SUCCESS:
      return {
        ...state,
        listClaimHistory: payload,
      }
    default:
      return state
  }
}
