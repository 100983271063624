const actions = {
  GET_DIVIDEND_INVEST: 'GET_DIVIDEND_INVEST',
  GET_DIVIDEND_INVEST_SUCCESS: 'GET_DIVIDEND_INVEST_SUCCESS',
  UPDATE_DIVIDEND_INVEST: 'UPDATE_DIVIDEND_INVEST',
  DELETE_DIVIDEND_INVEST: 'DELETE_DIVIDEND_INVEST',

  SUBMIT_DATA_RATE: 'SUBMIT_DATA_RATE',

  getDividendInvest: (payload) => ({
    type: actions.GET_DIVIDEND_INVEST,
    payload,
  }),
  getDividendInvestSuccess: (data) => ({
    type: actions.GET_DIVIDEND_INVEST_SUCCESS,
    data,
  }),
  updateDividendInvest: (payload, cbs, cbe) => ({
    type: actions.UPDATE_DIVIDEND_INVEST,
    payload,
    cbs,
    cbe,
  }),
  deleteDividendInvest: (payload, cbs, cbe) => ({
    type: actions.DELETE_DIVIDEND_INVEST,
    payload,
    cbs,
    cbe,
  }),

  submitDataRate: (payload, cbs, cbe) => ({
    type: actions.SUBMIT_DATA_RATE,
    payload,
    cbs,
    cbe,
  }),
}
export default actions
