import { all, call, takeLatest } from 'redux-saga/effects'
import fetchHelper from 'library/FetchHelper'
import { ROOT_API } from 'config/constants'
import actions from './actions'

function createInvestmentAirdropFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/investment/airdrop`, {
      method: 'POST',
      body: JSON.stringify(payload)
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* createInvestmentAirdrop({ payload, cbe, cbs }) {
  try {
    const { data } = yield call(createInvestmentAirdropFromApi, payload,)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else if (cbe) cbe(data)
  } catch (error) {
    console.error('createInvestmentAirdrop', error)
    if (cbe) cbe(error)
  }
}

export default function* investmentAirdropSaga() {
  yield all([takeLatest(actions.CREATE_INVESTMENT_AIRDROP, createInvestmentAirdrop)])
}
