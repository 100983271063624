import { all, takeEvery, call, put, fork, select } from 'redux-saga/effects'
import { groupBy } from 'lodash'
import fetchHelper from 'library/FetchHelper'
import { ROOT_API } from 'config/constants'
import actions from './actions'
import * as qs from 'query-string'

function getCurrencyFromApi(payload) {
  const qsString = qs.stringify(payload)
  return fetchHelper
    .fetch(`${ROOT_API}/currency/list?${qsString}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getListCurrency() {
  yield takeEvery(actions.GET_LIST_CURRENCY, function* ({ payload }) {
    try {
      const { data } = yield call(getCurrencyFromApi, payload)
      if (data.code === 200) {
        const listCurrencyId = []
        data.data.forEach((curr) => {
          listCurrencyId.push(curr._id)
        })
        // save currency list
        yield put(actions.getListCurrencySuccess(payload, data.data))
        // save currency list with address of currency
        yield put(actions.getAddressByCurrencyList({ listCurrencyId, listCurrency: data.data }))
      }
    } catch (error) {
      console.error('getListCurrency', error)
    }
  })
}

/**
 * Get address of token by currency list _id
 */
function getAddressByCurrencyListFromApi(payload) {
  const qsString = qs.stringify(payload)
  return fetchHelper
    .fetch(`${ROOT_API}/currencyAttr/list?${qsString}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getAddressByCurrencyList() {
  yield takeEvery(actions.GET_ADDRESS_BY_CURRENCY_LIST, function* ({ payload }) {
    try {
      const { listCurrencyId, listCurrency } = payload
      // fetch list address currency by currency id
      const fetchList = []
      for (let i = 0; i < listCurrencyId.length; i++) {
        const element = listCurrencyId[i]
        fetchList.push(call(getAddressByCurrencyListFromApi, { currency_id: element }))
      }

      const response = yield all(fetchList)
      if (response && response.length > 0) {
        const dataAddressByCurrencyId = {}
        response.forEach((element, index) => {
          if (element.data.code === 200) {
            const tokenId = listCurrencyId[index]
            dataAddressByCurrencyId[tokenId] = element.data.data
          }
        })
        let listBlockChain = yield select((state) => state?.BlockChain?.listBlockChain?.data)
        listBlockChain = listBlockChain.filter((o) => o.chain_code)

        const mapCurrencyWithAddress = [...listCurrency].map((currency) => {
          const tmpCurrency = currency
          const dataAddressByCurrencyIdItem = dataAddressByCurrencyId[currency._id]
          listBlockChain.forEach((blockchain) => {
            const fAddress = dataAddressByCurrencyIdItem.find((p) => p.blockchain === blockchain._id)
            tmpCurrency[blockchain._id] = fAddress?.contract
            tmpCurrency[blockchain.chain_code] = fAddress?.contract
            tmpCurrency.chain_code = blockchain.chain_code
          })
          return tmpCurrency
        })
        const grByChain = groupBy(mapCurrencyWithAddress, (f) => f.chain_code)

        yield put(actions.getAddressByCurrencyListSuccess(listCurrencyId, grByChain))
      }
    } catch (error) {
      console.error('getAddressByCurrencyList', error)
    }
  })
}

function getDetailCurrencyFromApi(payload) {
  const qsString = qs.stringify(payload)
  return fetchHelper
    .fetch(`${ROOT_API}/admin/currency/detail?${qsString}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getDetailCurrency() {
  yield takeEvery(actions.GET_DETAIL_CURRENCY, function* ({ payload, cbs, cbe }) {
    try {
      const { data } = yield call(getDetailCurrencyFromApi, payload, cbs, cbe)
      if (data.code === 200) {
        if (cbs) cbs(data.data)
      } else if (cbe) cbe(data)
    } catch (error) {
      console.error('getDetailCurrency', error)
      if (cbe) cbe(error)
    }
  })
}

function createCurrencyFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/currency/create`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* createCurrency() {
  yield takeEvery(actions.CREATE_CURRENCY, function* ({ payload, cbs, cbe }) {
    try {
      const { data } = yield call(createCurrencyFromApi, payload, cbs, cbe)
      if (data.code === 200) {
        if (cbs) cbs(data.data)
      } else if (cbe) cbe(data)
    } catch (error) {
      console.error('createCurrency', error)
      if (cbe) cbe(error)
    }
  })
}

function updateCurrencyFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/currency/update`, {
      method: 'PUT',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* updateCurrency() {
  yield takeEvery(actions.UPDATE_CURRENCY, function* ({ payload, cbs, cbe }) {
    try {
      const { data } = yield call(updateCurrencyFromApi, payload, cbs, cbe)
      if (data.code === 200) {
        if (cbs) cbs(data.data)
      } else if (cbe) cbe(data)
    } catch (error) {
      console.error('updateCurrency', error)
      if (cbe) cbe(error)
    }
  })
}

function deleteCurrencyAttrFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/currency/deleteAttr`, {
      method: 'DELETE',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* deleteCurrencyAttr() {
  yield takeEvery(actions.DELETE_CURRENCY_ATTR, function* ({ payload, cbs, cbe }) {
    try {
      const { data } = yield call(deleteCurrencyAttrFromApi, payload, cbs, cbe)
      if (data.code === 200) {
        if (cbs) cbs(data.data)
      } else if (cbe) cbe(data)
    } catch (error) {
      console.error('deleteCurrencyAttr', error)
      if (cbe) cbe(error)
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(getListCurrency),
    fork(getAddressByCurrencyList),
    fork(getDetailCurrency),
    fork(createCurrency),
    fork(updateCurrency),
    fork(deleteCurrencyAttr),
  ])
}
