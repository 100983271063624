import actions from './actions'

const initState = { listWalletChain: null, listWalletWithBalance: {}, listAllBalanceByWalletData: {} }

export default function authReducer(state = initState, action) {
  const { data, payload } = action
  switch (action.type) {
    case actions.GET_ADMIN_WALLET_LIST_SUCCESS:
      return {
        ...state,
        listWalletChain: data,
      }
    case actions.GET_ADMIN_WALLET_BALANCE_SUCCESS:
      const currentData = state.listWalletWithBalance
      if (!currentData[payload.chain_code]) currentData[payload.chain_code] = {}
      currentData[payload.chain_code] = {
        ...currentData[payload.chain_code],
        ...data,
      }

      const entriesCurrencyWithAccount = state.listWalletChain.rows
        .map((wallet) => {
          const fBalance = Object.entries(currentData[payload.chain_code]).find((item) => item[0] === wallet.address) || {}
          if (fBalance) {
            return {
              ...wallet,
              ...fBalance[1],
            }
          }
          return undefined
        })
        .filter((o) => o)

      return {
        ...state,
        // for save and replace data
        listWalletWithBalance: currentData,
        // for show data to table
        listAllBalanceByWalletData: {
          ...state.listAllBalanceByWalletData,
          [payload.chain_code]: entriesCurrencyWithAccount,
        },
      }
    default:
      return state
  }
}
