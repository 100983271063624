const actions = {
  GET_LIST_TEAM_WALLET: 'GET_LIST_TEAM_WALLET',
  GET_LIST_TEAM_WALLET_SUCCESS: 'GET_LIST_TEAM_WALLET_SUCCESS',
  DELETE_TEAM_WALLET: 'DELETE_TEAM_WALLET',
  CREATE_TEAM_WALLET: 'CREATE_TEAM_WALLET',
  UPDATE_TEAM_WALLET: 'UPDATE_TEAM_WALLET',
  DETAIL_TEAM_WALLET: 'DETAIL_TEAM_WALLET',

  createTeamWallet: (payload, cbe, cbs) => ({
    type: actions.CREATE_TEAM_WALLET,
    payload,
    cbe,
    cbs,
  }),

  getDetailTeamWallet: (payload, cbe) => ({
    type: actions.DETAIL_TEAM_WALLET,
    payload,
    cbe,
  }),

  getListTeamWallet: (payload, cbe) => ({
    type: actions.GET_LIST_TEAM_WALLET,
    payload,
    cbe,
  }),

  getListTeamWalletSuccess: (payload, cbe) => ({
    type: actions.GET_LIST_TEAM_WALLET_SUCCESS,
    payload,
    cbe,
  }),

  deleteTeamWallet: (payload, cbe) => ({
    type: actions.DELETE_TEAM_WALLET,
    payload,
    cbe,
  }),

  updateTeamWallet: (payload, cbe, cbs) => ({
    type: actions.UPDATE_TEAM_WALLET,
    payload,
    cbe,
    cbs,
  }),
}
export default actions
