export const PUBLIC_ROUTE = {
  SIGN_IN: '/signin',
  SIGN_UP: '/signup',
  FORGET_PASSWORD: '/forgotpassword',
  RESET_PASSWORD: '/resetpassword',
  PAGE_404: '/404',
  PAGE_500: '/500',
}

export const PRIVATE_ROUTE = {
  DASHBOARD: '/dashboard',
  PROFILE: '/my-profile',
  MANAGER_ADMIN_USER: '/manager/admin',
  MANAGER_ADMIN_USER_CREATE: '/manager/create/admin',

  MANAGER_CURRENCY: '/manager/currency',
  MANAGER_CURRENCY_CREATE: '/manager/create/currency',
  MANAGER_CURRENCY_UPDATE: '/manager/update/currency/:id',

  MANAGER_CHAIN: '/manager/chain',
  MANAGER_WALLET_ADMIN: '/manager/wallet/list',
  MANAGER_WALLET_ADMIN_CREATE: '/manager/wallet/create',
  MANAGER_WALLET_ADMIN_UPDATE: '/manager/wallet/update/:id',

  MANAGER_COUNTRY: '/manager/country',
  MANAGER_CUSTOMER_UPDATE: '/update/customer/:id',
  MANAGER_CUSTOMER_DETAILS: '/details/customer/:id',
  MANAGER_WALLET_DETAILS: '/details-wallet/:id',

  REPORT_WALLET: '/report/wallet',
  REPORT_CUSTOMER: '/report/customer/list',
  REPORT_CUSTOMER_CREATE: '/report/customer/create',
  REPORT_TRANSACTION: '/report/transaction',

  STAKING_LIST: '/packages',
  GAME_MANAGER: '/game-manager',
  CHECK_HASH_DEPOSIT: '/check-hash-deposit',

  INVESTMENT_AIRDROP: '/investment-airdrop',

  DIVIDEND_INVEST: '/dividend-invest',
  DIVIDEND_COMMISSION_INVEST: '/dividend-commission-invest',

  INVESTMENT: '/investment/list',
  INVESTMENT_CREATE: '/investment/create',
  INVESTMENT_DETAILS: '/details/investment/:id',
  INVESTMENT_UPDATE: '/update/investment/:id',

  INVESTMENT_HISTORY: '/investment/history',
  INVESTMENT_HISTORY_DETAILS: '/investment/details/history/:id',

  INVESTMENT_DIVIDEND: '/investment/dividend',
  HISTORY_CLAIM_TOKEN: '/claim/list',
  CLAIM_TOKEN: '/claim/claim',
  WALLET_TEAM: '/wallet-team/list',
  WALLET_TEAM_CREATE: '/wallet-team/create',
  WALLET_TEAM_UPDATE: '/wallet-team/update/:id',

  DIRECT_COMMISSION_INVEST_LIST: '/direct-commission-invest/list/:package_id',
  DIRECT_COMMISSION_INVEST_CREATE: '/direct-commission-invest/create/:package_id',
  DIRECT_COMMISSION_INVEST_UPDATE: '/direct-commission-invest/update/:package_id/:commission_id',

  SYSTEM_COMMISSION_LIST: '/manager/system-commission/list',
  SYSTEM_COMMISSION_CREATE: '/manager/system-commission/create',
  SYSTEM_COMMISSION_UPDATE: '/manager/system-commission/details/:id',
}
