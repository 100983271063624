import React from 'react'
import { Modal } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import appActions from 'redux/app/actions'

export default function ModalConfirm({ visible, dataModal, onFinish, content, title, ...props }) {
  const dispatch = useDispatch()
  const { modalConfirm } = useSelector((state) => state.App)
  const { toggleModalConfirm } = appActions

  const onOk = () => {
    modalConfirm.action()

    dispatch(
      toggleModalConfirm({
        visible: false,
        data: {
          title: null,
          content: null,
          type: null,
        },
        action: () => {},
      }),
    )
  }

  const onCancel = () => {
    dispatch(
      toggleModalConfirm({
        visible: false,
        data: {
          title: null,
          content: null,
          type: null,
        },
        action: () => {},
      }),
    )
  }

  return (
    <Modal visible={modalConfirm.visible} title={modalConfirm.data.title} centered onOk={onOk} onCancel={onCancel} {...props}>
      {modalConfirm.data.content}
    </Modal>
  )
}
