const actions = {
  CREATE_DIVIDEND_COMMISSION: 'CREATE_DIVIDEND_COMMISSION',
  GET_DIVIDEND_COMMISSION_INVEST: 'GET_DIVIDEND_COMMISSION_INVEST',
  GET_DIVIDEND_COMMISSION_INVEST_SUCCESS: 'GET_DIVIDEND_COMMISSION_INVEST_SUCCESS',
  UPDATE_DIVIDEND_COMMISSION_INVEST: 'UPDATE_DIVIDEND_COMMISSION_INVEST',
  DELETE_DIVIDEND_COMMISSION_INVEST: 'DELETE_DIVIDEND_COMMISSION_INVEST',

  createDividendCommission: (payload, cbs, cbe) => ({
    type: actions.CREATE_DIVIDEND_COMMISSION,
    payload,
    cbs,
    cbe,
  }),
  getDividendCommissionInvest: (payload) => ({
    type: actions.GET_DIVIDEND_COMMISSION_INVEST,
    payload,
  }),
  getDividendCommissionInvestSuccess: (data) => ({
    type: actions.GET_DIVIDEND_COMMISSION_INVEST_SUCCESS,
    data,
  }),
  updateDividendCommissionInvest: (payload, cbs, cbe) => ({
    type: actions.UPDATE_DIVIDEND_COMMISSION_INVEST,
    payload,
    cbs,
    cbe,
  }),
  deleteDividendCommissionInvest: (payload, cbs, cbe) => ({
    type: actions.DELETE_DIVIDEND_COMMISSION_INVEST,
    payload,
    cbs,
    cbe,
  }),
}
export default actions
