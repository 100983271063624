import { all, call, put, takeEvery } from 'redux-saga/effects'
import fetchHelper from 'library/FetchHelper'
import { ROOT_API } from 'config/constants'
import actions from './actions'
import * as qs from 'query-string'

function getListTeamWalletFromApi(payload) {
  const qStr = qs.stringify(payload)

  return fetchHelper
    .fetch(`${ROOT_API}/admin/teamWallet/list?${qStr}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getListTeamWallet({ payload, cbe }) {
  try {
    const { data } = yield call(getListTeamWalletFromApi, payload)
    if (data.code === 200) {
      yield put({
        type: actions.GET_LIST_TEAM_WALLET_SUCCESS,
        payload: data.data,
      })
      if (cbe) cbe(data.data)
    } else {
      yield put({
        type: actions.GET_LIST_TEAM_WALLET_SUCCESS,
        payload: null,
      })
    }
  } catch (error) {
    console.error('getListTeamWallet', error)
    if (cbe) cbe(error)
  }
}

function getDetailTeamWalletFromApi(payload) {
  const qStr = qs.stringify(payload)

  return fetchHelper
    .fetch(`${ROOT_API}/admin/teamWallet/detail?${qStr}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getDetailTeamWallet({ payload, cbe }) {
  try {
    const { data } = yield call(getDetailTeamWalletFromApi, payload)
    if (data.code === 200) {
      if (cbe) cbe(data)
    }
  } catch (error) {
    console.error('getDetailTeamWallet', error)
    if (cbe) cbe(error)
  }
}

function deleteTeamWalletFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/teamWallet/delete`, {
      method: 'DELETE',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* deleteTeamWallet({ payload, cbe }) {
  try {
    const { data } = yield call(deleteTeamWalletFromApi, payload)
    if (data.code === 200) {
      if (cbe) cbe(data.data)
    }
  } catch (error) {
    console.error('deleteTeamWallet', error)
    if (cbe) cbe(error)
  }
}

function createTeamWalletFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/teamWallet/create`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* createTeamWallet({ payload, cbe, cbs }) {
  try {
    const { data } = yield call(createTeamWalletFromApi, payload)
    if (data.code === 200) {
      if (cbe) cbe(data.data)
    }
  } catch (error) {
    console.error('createTeamWallet', error)
    if (cbs) cbs(error)
  }
}

function updateTeamWalletFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/teamWallet/update`, {
      method: 'PUT',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* updateTeamWallet({ payload, cbe, cbs }) {
  try {
    const { data } = yield call(updateTeamWalletFromApi, payload)
    if (data.code === 200) {
      if (cbe) cbe(data)
    }
  } catch (error) {
    console.error('updateTeamWallet', error)
    if (cbs) cbs(error)
  }
}

export default function* investmentSaga() {
  yield all([
    takeEvery(actions.GET_LIST_TEAM_WALLET, getListTeamWallet),
    takeEvery(actions.DELETE_TEAM_WALLET, deleteTeamWallet),
    takeEvery(actions.CREATE_TEAM_WALLET, createTeamWallet),
    takeEvery(actions.UPDATE_TEAM_WALLET, updateTeamWallet),
    takeEvery(actions.DETAIL_TEAM_WALLET, getDetailTeamWallet),
  ])
}
