const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',

  GET_LIST_SYSTEM_COMMISSION: 'GET_LIST_SYSTEM_COMMISSION',
  GET_LIST_SYSTEM_COMMISSION_SUCCESS: 'GET_LIST_SYSTEM_COMMISSION_SUCCESS',
  CREATE_SYSTEM_COMMISSION: 'CREATE_SYSTEM_COMMISSION',
  UPDATE_SYSTEM_COMMISSION: 'UPDATE_SYSTEM_COMMISSION',
  DELETE_SYSTEM_COMMISSION: 'DELETE_SYSTEM_COMMISSION',

  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),

  getListSystemCommission: (payload, cbs, cbe) => ({
    type: actions.GET_LIST_SYSTEM_COMMISSION,
    payload,
    cbs,
    cbe,
  }),

  createSystemCommission: (payload, cbs, cbe) => ({
    type: actions.CREATE_SYSTEM_COMMISSION,
    payload,
    cbs,
    cbe,
  }),

  updateSystemCommission: (payload, cbs, cbe) => ({
    type: actions.UPDATE_SYSTEM_COMMISSION,
    payload,
    cbs,
    cbe,
  }),

  deleteSystemCommission: (payload, cbs, cbe) => ({
    type: actions.DELETE_SYSTEM_COMMISSION,
    payload,
    cbs,
    cbe,
  }),
}
export default actions
