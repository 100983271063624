import actions from './actions'

const initState = { dividendInvest: undefined }

export default function dividendCommissionInvestReducer(state = initState, action) {
  const { data } = action
  switch (action.type) {
    case actions.GET_DIVIDEND_COMMISSION_INVEST_SUCCESS:
      return {
        ...state,
        dividendCommissionInvest: data,
      }

    default:
      return state
  }
}
