import actions from './actions'

const initState = {
  listInvestment: null,
  listInvestmentHistory: null,
  listDirectCommissionInvest: null,
  investmentDetail: {
    view: 0,
    data: null,
  },
}

export default function investment(state = initState, action) {
  const { type, payload } = action

  switch (type) {
    case actions.GET_INVESTMENT_LIST_SUCCESS:
      return {
        ...state,
        listInvestment: payload,
      }
    case actions.GET_INVESTMENT_HISTORY_LIST_SUCCESS:
      return {
        ...state,
        listInvestmentHistory: payload,
      }
    case actions.GET_LIST_DIRECT_COMMISSION_INVEST_SUCCESS:
      return {
        ...state,
        listDirectCommissionInvest: payload,
      }
    case actions.SET_INVEST_DETAIL:
      return {
        ...state,
        investmentDetail: payload,
      }
    default:
      return state
  }
}
